import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";

// Options for Select components
const MODE_OPTIONS = [
  { label: "Truncate", value: "Truncate" },
  { label: "Upsert", value: "Upsert" },
  { label: "Append", value: "Append" },
];

const DESTINATION_OPTIONS = [
  { label: "True", value: true },
  { label: "False", value: false },
];

const PopUpModel = (props) => {
  const [schemaName, setSchemaName] = useState("");
  const [tableName, setTableName] = useState("");
  const [mode, setMode] = useState(null);
  const [destinationStatus, setDestinationStatus] = useState(null);
  const [ownerName, setOwnerName] = useState("");
  const [primaryColumn, setPrimaryColumn] = useState("");
  const [timeStampColumn, setTimeStampColumn] = useState("");
  const [primaryColumnList, setPrimaryColumnList] = useState([]);
  const [timestampColumnList, setTimestampColumnList] = useState([]);

  // Initialize state with active session data if available
  useEffect(() => {
    if (props.activeSession) {
      const {
        schema,
        tablename,
        mode,
        destinationTable,
        owner,
        primarycolumn,
        incremental_time_column,
      } = props.activeSession;
      setSchemaName(schema || "");
      setTableName(tablename || "");
      setOwnerName(owner);
      setMode({ label: mode || "", value: mode || "" });

      if (destinationTable === true) {
        setDestinationStatus({
          label: "True" || "",
          value: "true" || "",
        });
      } else {
        setDestinationStatus({
          label: "False" || "",
          value: "false" || "",
        });
      }
      setPrimaryColumnList(primarycolumn);
      setTimestampColumnList(incremental_time_column);
    }
  }, [props.activeSession]);

  // Handler to add a primary column
  const addPrimaryColumn = useCallback(() => {
    if (primaryColumn.trim()) {
      setPrimaryColumnList((prevList) => [
        ...prevList,
        { columnName: primaryColumn },
      ]);
      setPrimaryColumn("");
    }
  }, [primaryColumn]);

  // Handler to add a timestamp column
  const addTimeStamp = useCallback(() => {
    if (timeStampColumn.trim()) {
      setTimestampColumnList((prevList) => [
        ...prevList,
        { columnName: timeStampColumn },
      ]);
      setTimeStampColumn("");
    }
  }, [timeStampColumn]);

  // Handler to delete a primary column by index
  const deletePrimaryColumn = useCallback((index) => {
    setPrimaryColumnList((prevList) => prevList.filter((_, i) => i !== index));
  }, []);

  // Handler to delete a timestamp column by index
  const deleteTimeStamp = useCallback((index) => {
    setTimestampColumnList((prevList) =>
      prevList.filter((_, i) => i !== index)
    );
  }, []);

  // Handles form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "primaryColumn") {
      setPrimaryColumn(value);
    } else if (name === "timestamp") {
      setTimeStampColumn(value);
    }
  };

  // Handles form submission
  const handleSubmit = () => {
    const request = {
      schema: schemaName,
      table: tableName,
      mode: mode ? mode.value : "",
      primary_key: primaryColumnList,
      incremental_time_column: timestampColumnList,
      dest_table_creation: destinationStatus ? destinationStatus.value : null,
      owner: ownerName,
    };
    props.togglePoputFull(false, request);
  };

  return (
    <div className="pageview-popup no-scroll">
      <div className="pageview-header-wrap">
        <div className="pageview-header">EXPORT QUERY OUTPUT</div>
        <div
          className="close-btn"
          onClick={() => props.togglePoputFull(false, "")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <circle cx="16" cy="16" r="16" fill="#F3F5F7" />
            <path
              d="M7.56632 6.56246C7.25678 6.66944 7 7.0318 7 7.36656C7 7.43558 7.02462 7.57017 7.05628 7.66335C7.10904 7.81865 7.42914 8.14305 10.9361 11.5872L14.7562 15.3385L10.9502 19.076C8.86078 21.1294 7.1196 22.8619 7.08794 22.9274C6.92965 23.238 7.00352 23.6625 7.26382 23.9144C7.50653 24.156 7.83718 24.2285 8.1819 24.1181C8.34018 24.0663 8.67083 23.7522 12.1778 20.3115L16.0049 16.5637L19.832 20.3115C23.339 23.7522 23.6696 24.0663 23.8279 24.1181C24.1727 24.2285 24.5033 24.156 24.746 23.9144C24.9922 23.6763 25.0661 23.3519 24.9535 23.0137C24.9008 22.8584 24.5807 22.534 21.0737 19.0899L17.2536 15.3385L21.0737 11.5838C24.5807 8.14305 24.9008 7.81865 24.9535 7.66335C25.0661 7.32515 24.9922 7.00074 24.746 6.76262C24.5033 6.52105 24.1727 6.44857 23.8279 6.55901C23.6696 6.61077 23.339 6.92482 19.8285 10.3655L16.0049 14.1134L12.1778 10.3655C8.67083 6.92482 8.34018 6.61077 8.1819 6.55901C7.96732 6.48999 7.77034 6.49344 7.56632 6.56246Z"
              fill="#585858"
            />
          </svg>
        </div>
      </div>
      <div className="pageview-body">
        <div className="form-group">
          <label htmlFor="destination">Create Destination Table</label>
          <p className="form-desc">
            Select if a destination table should be created
          </p>
          <div className="form-wrapper">
            <Select
              value={destinationStatus}
              name="destination"
              options={DESTINATION_OPTIONS}
              placeholder="--Select if a destination table needs to be created--"
              onChange={setDestinationStatus}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="owner">Owner</label>
          <p className="form-desc">Provide the owner's name</p>
          <div className="form-wrapper">
            <input
              id="owner"
              autoComplete="off"
              className="form-control"
              placeholder="Enter the owner's name"
              value={ownerName}
              onChange={(e) => setOwnerName(e.target.value)}
            />
          </div>
        </div>

        <div className="d-flex gap-20">
          <div className="form-group">
            <label htmlFor="schemaName">Schema Name</label>
            <p className="form-desc">Enter schema name</p>
            <div className="form-wrapper">
              <input
                id="schemaName"
                autoComplete="off"
                className="form-control"
                placeholder="Enter the schema name"
                value={schemaName}
                onChange={(e) => setSchemaName(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="tableName">Table Name</label>
            <p className="form-desc">Enter table name</p>
            <div className="form-wrapper">
              <input
                id="tableName"
                className="form-control"
                placeholder="Enter the table name"
                autoComplete="off"
                value={tableName}
                onChange={(e) => setTableName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="mode">Mode</label>
          <p className="form-desc">Select the operation mode</p>
          <div className="form-wrapper">
            <Select
              value={mode}
              name="mode"
              options={MODE_OPTIONS}
              placeholder="--Select Operation Mode--"
              onChange={setMode}
            />
          </div>
        </div>
        {mode?.value === "Upsert" && (
          <>
            <div className="d-flex gap-10">
              <div className="form-group">
                <label htmlFor="primaryColumn">Primary Keys</label>
                <p className="form-desc">Enter primary keys</p>
                <div className="form-wrapper">
                  <input
                    id="primaryColumn"
                    className="form-control"
                    placeholder="Enter primary column"
                    name="primaryColumn"
                    autoComplete="off"
                    value={primaryColumn}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <button className="form-btn-secondary" onClick={addPrimaryColumn}>
                + Add
              </button>
            </div>
            {primaryColumnList.map((data, index) => (
              <div
                key={index}
                className="form-wrapper d-flex gap-30 align-center pd-right-20 mg-top-10"
              >
                <input
                  className="form-control"
                  placeholder="Primary column"
                  value={data.columnName}
                  readOnly
                />
                <div className="float-right">
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => deletePrimaryColumn(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <circle
                      cx="8.50024"
                      cy="8"
                      r="7.5"
                      fill="#DCDCDC"
                      stroke="#00275A"
                    />
                    <path d="M3.86865 8L13.1318 8" stroke="#00275A" />
                  </svg>
                </div>
              </div>
            ))}

            <div className="d-flex gap-10">
              <div className="form-group">
                <label htmlFor="timeStampColumn">Incremental Timestamp</label>
                <p className="form-desc">Enter last updated timestamp</p>
                <div className="form-wrapper">
                  <input
                    id="timeStampColumn"
                    className="form-control"
                    placeholder="Enter timestamp column"
                    name="timestamp"
                    autoComplete="off"
                    value={timeStampColumn}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <button className="form-btn-secondary" onClick={addTimeStamp}>
                + Add
              </button>
            </div>
            {timestampColumnList.map((data, index) => (
              <div
                key={index}
                className="form-wrapper d-flex gap-30 align-center pd-right-20 mg-top-10"
              >
                <input
                  className="form-control"
                  placeholder="Timestamp column"
                  value={data.columnName}
                  readOnly
                />
                <div className="float-right">
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => deleteTimeStamp(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <circle
                      cx="8.50024"
                      cy="8"
                      r="7.5"
                      fill="#DCDCDC"
                      stroke="#00275A"
                    />
                    <path d="M3.86865 8L13.1318 8" stroke="#00275A" />
                  </svg>
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      <div className="pageview-footer">
        <button
          type="button"
          className="add-session-button"
          onClick={handleSubmit}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PopUpModel;
