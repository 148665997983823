import { useState, useCallback, useRef } from "react";
import "./App.css";
import logo from "../logo.svg";
import "react-calendar/dist/Calendar.css";
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-github";
import PopUpModel from "./popupModel";
import { OverlayPanel } from "primereact/overlaypanel";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { PrimeReactProvider } from "primereact/api";
import Sidebar from "./sidebar";
import { appAPIModule } from "../API/appAPI";
import Calendar from "react-calendar";
import { format } from "date-fns";
import { Dialog } from "primereact/dialog";
import { toastNotify } from "../Utilities/toastServices";
import { loader } from "../Utilities/loader";

const MODE_OPTIONS = [
  { label: "Truncate & load", value: "Truncate & load" },
  { label: "Upsert", value: "Upsert" },
  { label: "Append", value: "Append" },
];

const FREQUENCY_OPTIONS = [
  { label: "5min", value: "*/5 * * * *" },
  { label: "10min", value: "*/10 * * * *" },
  { label: "15min", value: "*/15 * * * *" },
  { label: "30min", value: "*/30 * * * *" },
  { label: "1hour", value: "0 * * * *" },
  { label: "12hour", value: "0 */12 * * *" },
  { label: "24hour", value: "0 0 * * *" },
  { label: "Cron expression", value: "Cron expression" },
];

function MainModelWindow() {
  const op = useRef(null);
  const dropdownRef = useRef(null);
  const toast = useRef(null);
  const [sqlQuery, setSqlQuery] = useState("");
  const [activeSession, setActiveSession] = useState(null);
  const [showFullPopup, setShowFullPopup] = useState(false);
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [frequency, setFrequency] = useState("");
  const [cronExpression, setCronExpression] = useState("");
  const [requestData, setRequestData] = useState({});
  const [modelName, setModelName] = useState("");

  const onClickDate = useCallback((value) => {
    const formattedDate = format(value, "yyyy-MM-dd");
    setStartDate(formattedDate);
    setShowCalendar(false);
  }, []);

  const selectScheduleTime = useCallback((data) => {
    setFrequency(data);
  }, []);

  const handleFileSelect = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSqlQuery(e.target.result);
      };
      reader.readAsText(file);
    }
  }, []);

  const onChange = useCallback((newValue) => {
    setSqlQuery(newValue);
  }, []);

  const handleSessionClick = useCallback((session) => {
    console.log(session);
    setSqlQuery(session.query);
    setActiveSession(session);
    setStartDate(session.startdate);
    setModelName(session.modelname);
    setFrequency(session.interval);
  }, []);

  const togglePopupFull = useCallback((status, requestBody) => {
    setShowFullPopup(status);
    setRequestData(requestBody);
  }, []);

  const openModelName = useCallback((status) => {
    if (status) {
      setModelName("");
      setActiveSession(null);
      setStartDate("");
      setFrequency("");
      setSqlQuery("");
    }
    setVisible(status);
  }, []);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <div>Model Name</div>
    </div>
  );

  const footerContent = (
    <div className="scheduler-footer">
      <button className="upload-button" onClick={() => openModelName(false)}>
        Next
      </button>
    </div>
  );

  const handleFormSubmit = useCallback(() => {
    loader("start");

    let interval = frequency.value;
    if (frequency == "Cron expression") {
      interval = cronExpression;
    }

    const extendedRequestData = {
      ...requestData,
      modelname: modelName,
      startdate: startDate,
      scheduleinterval: interval,
      query: sqlQuery,
    };

    appAPIModule.submitForm(extendedRequestData).then((response) => {
      if (response.status === 200) {
        loader("stop");
        toastNotify("Model created successfully", "success");
      } else {
        toastNotify("There was internal error", "success");
      }
    });
  }, [requestData, modelName, startDate, frequency, sqlQuery, cronExpression]);

  return (
    <PrimeReactProvider>
      <div className={`poput-full ${showFullPopup ? "slideIn" : ""}`}>
        <PopUpModel
          showFullPopup={showFullPopup}
          togglePoputFull={togglePopupFull}
          activeSession={activeSession}
        />
      </div>
      <div className="chat-session">
        <div className="chat-header">
          <img src={logo} alt="Shellkode Logo" className="header-logo" />
          <button className="logout-button">Reset</button>
        </div>
        <div className="main-content">
          <Sidebar
            activeSession={activeSession}
            handleSessionClick={handleSessionClick}
            openModelName={openModelName}
          />
          <div className="d-flex flex-column flex-1 gap-20">
            <div className="sub-container-header d-flex justify-between">
              <label>{modelName}</label>
              <div
                className="float-right d-flex align-center qwe"
                icon="pi pi-image"
                label="Image"
                onClick={(e) => op.current.toggle(e)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5"
                  height="20"
                  viewBox="0 0 5 20"
                  fill="none"
                >
                  <circle cx="2.5" cy="2" r="2" fill="black" />
                  <circle cx="2.5" cy="10" r="2" fill="black" />
                  <circle cx="2.5" cy="18" r="2" fill="black" />
                </svg>
              </div>
              <OverlayPanel ref={op}>
                <ul className="model-overlay-panel">
                  <li className="model-settings"> Delete</li>
                </ul>
              </OverlayPanel>
            </div>
            <div className="form-main-content">
              <div className="form-sub-container">
                <label htmlFor="sql-query">SQL Query</label>
                <p className="form-desc">Enter query</p>
                <div className="query-container">
                  <AceEditor
                    id="editor"
                    aria-label="editor"
                    mode="mysql"
                    theme="github"
                    name="editor"
                    fontSize={16}
                    minLines={15}
                    maxLines={10}
                    width="100%"
                    showPrintMargin={false}
                    showGutter
                    placeholder="Write your Query here..."
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: true,
                    }}
                    value={sqlQuery}
                    onChange={onChange}
                    showLineNumbers
                  />
                </div>
                <div className="d-flex gap-10 justify-end">
                  <input
                    type="file"
                    id="file-upload"
                    style={{ display: "none" }}
                    onChange={handleFileSelect}
                  />
                  <button
                    className="upload-button"
                    onClick={() =>
                      document.getElementById("file-upload").click()
                    }
                  >
                    Upload
                  </button>
                  <button
                    className="upload-button"
                    onClick={() => setShowFullPopup(!showFullPopup)}
                  >
                    Build
                  </button>
                </div>
                <div className="form-group">
                  <label htmlFor="start-date">Start Date</label>
                  <p className="form-desc">
                    Enter the date when the airflow should start
                  </p>
                  <div className="form-wrapper">
                    <input
                      id="start-date"
                      className="form-control"
                      placeholder="Enter start date"
                      name="startDate"
                      autoComplete="off"
                      value={startDate}
                      onFocus={() => setShowCalendar(!showCalendar)}
                    />
                  </div>
                  {showCalendar && (
                    <Calendar onChange={onClickDate} ref={dropdownRef} />
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="frequency">Runs every</label>
                  <p className="form-desc">Select the frequency of the task</p>
                  <div className="scheduler-time-list">
                    {FREQUENCY_OPTIONS.map((data) => (
                      <div key={data.label}>
                        <button
                          onClick={() => selectScheduleTime(data)}
                          className={`scheduler-btn ${
                            frequency.label === data.label ? "active" : ""
                          }`}
                        >
                          {data.label}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                {frequency.label === "Cron expression" && (
                  <div className="form-group">
                    <label htmlFor="cron-expression">Cron expression</label>
                    <p className="form-desc">
                      Example: `0 4,13 * * *` runs at 4:00 AM and 1:00 PM every
                      day
                    </p>
                    <div className="form-wrapper">
                      <input
                        id="cron-expression"
                        className="form-control"
                        placeholder="Enter cron expression"
                        name="cronExpression"
                        autoComplete="off"
                        value={cronExpression}
                        onChange={(e) => setCronExpression(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="footer">
                <button className="upload-button" onClick={handleFormSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        visible={visible}
        modal
        blockScroll
        header={headerElement}
        footer={footerContent}
        appendTo={document.body}
        style={{ width: "50rem" }}
        onHide={() => setVisible(false)}
      >
        <div className="form-group">
          <label htmlFor="model-name">Model Name</label>
          <p className="form-desc">Enter model name</p>
          <div className="form-wrapper">
            <input
              id="model-name"
              className="form-control"
              placeholder="Enter model name"
              name="modelName"
              autoComplete="off"
              value={modelName}
              onChange={(e) => setModelName(e.target.value)}
            />
          </div>
        </div>
      </Dialog>
    </PrimeReactProvider>
  );
}

export default MainModelWindow;
