import React, { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { appAPIModule } from "../API/appAPI";

function Sidebar({ activeSession, handleSessionClick, openModelName }) {
  const [modelList, setModelList] = useState([]);

  useLayoutEffect(() => {
    getAllModels();
  }, []);

  const getAllModels = async () => {
    try {
      const response = await appAPIModule.getAllModels();
      console.log(response);

      setModelList(response.data);
    } catch (error) {
      console.error("Error fetching models:", error);
    }
  };

  return (
    <div className="sidebar">
      <div className="sidebar-heading d-flex flex-column">
        <div className="sidebar-heading">SQL Models List</div>
        <div className="search-wrapper">
          <input
            type="text"
            placeholder="Search for session..."
            className="search-input"
            autoComplete="off"
          />
          {/* Uncomment and adjust if needed
          <img src={searchIcon} alt="Search" className="search-icon" />
          */}
        </div>
      </div>

      <div className="session-list">
        {modelList.map((session) => (
          <div
            key={session.id} // Use session.id as the key
            className={`session ${
              activeSession?.id === session.id ? "active" : ""
            }`}
            onClick={() => handleSessionClick(session)}
          >
            {session.modelname}
          </div>
        ))}
      </div>
      <button
        className="add-session-button"
        onClick={() => openModelName(true)}
      >
        + New
      </button>
    </div>
  );
}

// PropTypes for validation
Sidebar.propTypes = {
  activeSession: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  handleSessionClick: PropTypes.func.isRequired,
  openModelName: PropTypes.func.isRequired,
};

export default Sidebar;
