import React, { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import MainModelWindow from "./Components/mainModelWindow";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loader } from "./Utilities/loader.jsx";
import Dashboard from "./Components/dahsboard.jsx";

function App() {
  return (
    <div className="main-container">
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </div>
  );
}

function Main() {
  // Lifecycle hook - useEffect
  useEffect(() => {
    loader("stop");
  }, []);
  return (
    <>
      <ToastContainer autoClose={800} pauseOnFocusLoss={false} />
      <div id="spinner-loader">
        <div className="spinner"></div>
      </div>
      <Routes>
        <Route path="/model" element={<MainModelWindow />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </>
  );
}

export default App;
