import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import logo from "../genBi_logo.svg";
import { TabView, TabPanel } from "primereact/tabview";

function Dashboard() {
  const [embedUrl, setEmbedUrl] = useState("");
  const [salesEmbedURL, setSalesEmbedURL] = useState("");
  const [activeSession, setActiveSession] = useState(0);

  useEffect(() => {
    fetchDashboardUrl();
    fetchSalesDashboardUrl();
  }, [activeSession]);

  const fetchDashboardUrl = async () => {
    try {
      const response = await axios.get("https://qs-embed.shellkode.ai/genbi");
      const data = response.data;
      setEmbedUrl(data);
    } catch (error) {
      console.error("Error fetching dashboard URL:", error);
    }
  };

  const fetchSalesDashboardUrl = async () => {
    try {
      const response = await axios.get(
        "https://qs-embed.shellkode.ai/dashboard"
      );
      const data = response.data;
      setSalesEmbedURL(data.body);
    } catch (error) {
      console.error("Error fetching dashboard URL:", error);
    }
  };

  return (
    <div className="chat-session">
      <div className="chat-header">
        <img src={logo} alt="Shellkode Logo" className="header-logo" />
        <button className="logout-button">Reset</button>
      </div>
      <div className="card">
        <TabView
          activeIndex={activeSession}
          onTabChange={(e) => setActiveSession(e.index)}
        >
          <TabPanel header="Claim & Revenue Analytics">
            <p className="m-0">
              {salesEmbedURL ? (
                <iframe
                  title="QuickSight Dashboard"
                  width="100%"
                  height="100vh"
                  src={salesEmbedURL}
                  frameBorder="0"
                  allowFullScreen
                />
              ) : (
                <p>Loading...</p>
              )}
            </p>
          </TabPanel>
          <TabPanel header="Gen BI" onTabChange={(e) => alert(e.index)}>
            <p className="m-0">
              {embedUrl ? (
                <iframe
                  title="QuickSight Dashboard"
                  width="100%"
                  height="100vh"
                  src={embedUrl}
                  frameBorder="0"
                  allowFullScreen
                />
              ) : (
                <p>Loading...</p>
              )}
            </p>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
}

export default Dashboard;
