import axios from "axios";
import { backendURL } from "../Configurations/configs";

const appAPIModule = {
  getAllModels: async () => {
    try {
      const response = await axios.get(`${backendURL}get_all_models`);
      return response;
    } catch (error) {
      console.error("Error in listWorkspace:", error);
      throw error;
    }
  },

  submitForm: async (request) => {
    try {
      const response = await axios.post(`${backendURL}generate-yaml`, request);
      return response;
    } catch (error) {
      console.error("Error in listWorkspace:", error);
      throw error;
    }
  },
};
export { appAPIModule };
